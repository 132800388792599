import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/joy';

import {
  ITableBodyItem,
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';

import { AnyObject, IPointsReviewsModel } from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  useBrowserHistoryFunctions,
  useEmployeesDefaultFilters,
  usePointReviewsTableFiltersConfiguration,
  usePointsReview,
  usePointsReviewFilter,
  useSearch,
} from 'src/modules/utils';
import { PointReviewsFilter } from './components/PointReviewsFilter';

const reportUrl = '/question-employee/points-to-review';

export const PointsReview = () => {
  const { t } = useTranslation();

  const mapFilterToQueryParams = useSearch();
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();

  const defaultEmployeesFilter = useEmployeesDefaultFilters();

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});

  const exportProps = useReportExportProps(reportUrl, true);

  const filter = usePointsReviewFilter(appliedFilters);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = usePointsReview(reportUrl, filter!.where!);

  const filterFieldsConfiguration = usePointReviewsTableFiltersConfiguration();

  const { getLabel, getFilterCommonPropsByFilterName } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(filters);
  };

  const handleRowClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: IPointsReviewsModel & ITableBodyItem,
  ) => {
    navigate({
      pathname: '/employees',
      search: mapFilterToQueryParams({
        review: true,
        employeeId: row.employeeId,
        filter: {
          ...defaultEmployeesFilter,
          where: {
            ...defaultEmployeesFilter.where,
            id: row.employeeId,
          },
        },
      }),
    });
  };

  return (
    <TableContextProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: isDataLoading ? 400 : 'auto',
          height: isDataLoading || !data.length ? '100%' : 'auto',
        }}
      >
        <TableToolbar>
          <TableTitle>{t('point_reviews.table_name')}</TableTitle>

          <TableActions>
            <PointReviewsFilter
              getLabel={getLabel}
              onFiltersFormSubmit={handleFiltersSubmit}
              getFilterCommonPropsByFilterName={
                getFilterCommonPropsByFilterName
              }
            />

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={requestFilters}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />
          </TableActions>
        </TableToolbar>

        <TableWithActions<IPointsReviewsModel>
          heightWithTabs
          data={data as any}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          limit={limit}
          page={page}
          onTableRowClick={handleRowClick}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </Box>
    </TableContextProvider>
  );
};
