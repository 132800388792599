import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AnyObject,
  ICountResponse,
  IFilterData,
  IFilterInclude,
  IFilterWhere,
  IQuestionCategoryTypeModel,
} from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useGenerateHeadCellsData } from 'src/modules/utils/hooks/table';
import { useDynamicBaseReport } from 'src/modules/utils/hooks/reports.hooks';
import { PointCollectionType, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export const useQuestionCategoryTypePermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.questionCategoryType.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.questionCategoryType.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.questionCategoryType.delete.id,
  );

  return {
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
  };
};

export interface IQuestionCategoryTypeFilterPanelFilters {
  name?: string;
  siteId?: number;
  pointCollectionType?: PointCollectionType;
}

export const useQuestionCategoryTypeTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      pointCollectionType: {
        value: '',
        property: 'pointCollectionType',
        operator: 'eq' as const,
      },
    }),
    [],
  );

export const useQuestionCategoryTypeInclusion = () => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo(
    () => [
      {
        relation: 'site',
        scope: {
          ...(!isEmpty(selectedSites)
            ? {
                where: {
                  id: {
                    inq: selectedSites,
                  },
                },
              }
            : {}),
        },
      },
    ],
    [selectedSites],
  );
};

export const useQuestionCategoryTypeComposeWhere = (
  where: Partial<IQuestionCategoryTypeModel>,
) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.name
        ? {
            name: {
              like: `%${where.name}%`,
            },
          }
        : {}),
      ...(where.pointCollectionType
        ? {
            pointCollectionType: {
              eq: where.pointCollectionType,
            },
          }
        : {}),
      ...(where.siteId
        ? {
            siteId: {
              eq: where.siteId,
            },
          }
        : {}),
    }),
    [where.pointCollectionType, where.name, where.siteId],
  );
};

export const useQuestionCategoryTypeFilter = (
  appliedFilters: IQuestionCategoryTypeFilterPanelFilters,
) => {
  const _include = useQuestionCategoryTypeInclusion();
  const _where = useQuestionCategoryTypeComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};

export const useQuestionCategoryTypeTableInclusionObject = () => {
  return React.useMemo(
    () => ({
      site: {
        relationType: 'inner',
      },
    }),
    [],
  );
};

export const useQuestionCategoryType = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();
  const inclusionObj = useQuestionCategoryTypeTableInclusionObject();

  const initialData: AnyObject[] = [];

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('question_category_type.name'),
      },
      {
        id: 'site.name',
        orderConfig: { orderBy: 'site.name' },
        label: t('question_category_type.site'),
      },
      {
        id: 'pointCollectionType',
        orderConfig: { orderBy: 'pointCollectionType' },
        label: t('question_category_type.pointCollectionType'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });
};
