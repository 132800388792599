import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  AnyObject,
  ICountResponse,
  IFilter,
  IFilterData,
  IFilterInclude,
  IFilterWhere,
  IPointCollectionModel,
} from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useGenerateHeadCellsData } from 'src/modules/utils/hooks/table';
import { useDynamicBaseReport } from 'src/modules/utils/hooks/reports.hooks';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { useMutation, useQueryClient } from 'react-query';
import Api from 'src/modules/utils/Api';
import { getQueryKey } from 'src/modules/utils/helpers/reactQuery.helpers';

export const usePointCollectionsPermissions = () => {
  const allowedToUpsert = useHasUserAccessToAction(
    manageEntitiesConfig.pointCollection.upsert.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.pointCollection.delete.id,
  );

  return {
    allowedToDelete,
    allowedToUpsert,
  };
};

export interface IPointCollectionsFilterPanelFilters {
  siteId?: number;
  noReturn?: number;
  verbalWarning?: number;
  writtenWarning?: number;
}

export const usePointCollectionTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      noReturn: {
        value: '',
        property: 'noReturn',
        operator: 'eq' as const,
      },
      verbalWarning: {
        value: '',
        property: 'verbalWarning',
        operator: 'eq' as const,
      },
      writtenWarning: {
        value: '',
        property: 'writtenWarning',
        operator: 'eq' as const,
      },
    }),
    [],
  );

export const usePointCollectionInclusion = (siteId?: number) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo(
    () => [
      {
        relation: 'site',
        scope: {
          ...(siteId
            ? {
                where: {
                  id: siteId,
                },
              }
            : !isEmpty(selectedSites)
            ? {
                where: {
                  id: {
                    inq: selectedSites,
                  },
                },
              }
            : {}),
        },
      },
    ],
    [selectedSites, siteId],
  );
};

export const usePointCollectionComposeWhere = (
  where: Partial<IPointCollectionModel>,
) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.noReturn
        ? {
            noReturn: {
              eq: `%${where.noReturn}%`,
            },
          }
        : {}),
      ...(where.verbalWarning
        ? {
            verbalWarning: {
              eq: where.verbalWarning,
            },
          }
        : {}),
      ...(where.writtenWarning
        ? {
            writtenWarning: {
              eq: where.writtenWarning,
            },
          }
        : {}),
    }),
    [where.noReturn, where.verbalWarning, where.writtenWarning],
  );
};

export const usePointCollectionFilter = (
  appliedFilters: IPointCollectionsFilterPanelFilters,
) => {
  const _include = usePointCollectionInclusion();
  const _where = usePointCollectionComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};

export const usePointCollectionTableInclusionObject = () => {
  return React.useMemo(
    () => ({
      site: {
        relationType: 'inner',
      },
    }),
    [],
  );
};

const getPointCollectionFetchParameters = (where: IFilterWhere) => {
  const filter: IFilter = { filter: { where } };
  const queryKey = getQueryKey('/point-collections', filter);

  return { filter, queryKey };
};

export const usePointCollection = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();
  const inclusionObj = usePointCollectionTableInclusionObject();

  const initialData: AnyObject[] = [];

  const { queryKey } = getPointCollectionFetchParameters(where);
  const queryClient = useQueryClient();

  const {
    data,
    fetchData,
    isDataLoading: isDataListLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  // get mutation functions for sending data to server
  const { mutate: upsert, isLoading: createLoading } = useMutation(
    (data: IPointCollectionModel[]) => Api.PointCollection.upsert(data),
    // invalidate data
    {
      onSuccess: () => {
        fetchData({
          filter: {
            where,
            include,
          },
        });
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  // submit function
  const sendToServer = (payload: IPointCollectionModel[]) => upsert(payload);

  const isDataLoading =
    createLoading || isDataListLoading || isCountDataLoading;

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'site.name',
        },
        label: t('point_collections.site'),
      },
      {
        id: 'verbalWarning',
        orderConfig: {
          orderBy: 'verbalWarning',
        },
        label: t('point_collections.verb_warn'),
      },
      {
        id: 'writtenWarning',
        orderConfig: {
          orderBy: 'writtenWarning',
        },
        label: t('point_collections.writ_warn'),
      },
      {
        id: 'noReturn',
        orderConfig: {
          orderBy: 'noReturn',
        },
        label: t('point_collections.no_return'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const report = useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });

  return {
    ...report,
    sendToServer,
  };
};
