import * as React from 'react';
import { Socket } from 'socket.io-client';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SocketClient } from '../Api';
import * as actions from '../../actions';
import { getUserObjectFromStorage } from '../user';
import { getCurrentRole } from '../../selectors/auth';
import { NotificationPriority } from '../../../config';
import { dispatchAction } from '../helpers/actionsDispatcher';
import { addProcessStatus } from '../../actions';

export enum NotificationEvents {
  fetchRecent = 'notification-fetch-recent',
  productionDataSyncStatus = 'production-data-sync-status',
  generateIncentiveReportStatusChange = 'generate-incentive-report-status-change',
}

export const useNotificationsDefaultFilters = () => {
  return React.useMemo(
    () => ({
      order: [`createdAt desc`],
      limit: 10,
      offset: 0,
    }),
    [],
  );
};

export const useSocketNotifications = () => {
  const { auth: { token = '' } = {} } = getUserObjectFromStorage();
  const [socket, setSocket] = React.useState<Socket | null>();
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  React.useEffect(() => {
    const socket = SocketClient.Notifications(token);
    setSocket(socket);
    return () => {
      socket?.close();
    };
  }, [token, currentRole]);

  return {
    socket,
  };
};

export const useNotifications = () => {
  // create dispatcher
  const dispatcher = useDispatch();
  const { socket } = useSocketNotifications();

  return React.useCallback(
    () =>
      socket?.on(NotificationEvents.fetchRecent, (notification) => {
        if (
          [NotificationPriority.highest, NotificationPriority.high].includes(
            notification.priority,
          )
        ) {
          dispatchAction(
            addProcessStatus({
              variant: notification.object === 'failed' ? 'error' : 'success',
              message: notification.message,
            }),
          );
        }

        dispatcher(actions.getRecentNotificationSuccess(notification));
      }),
    [dispatcher, socket],
  );
};

export const useShowNotificationOnProductionDataSyncStatusChange = () => {
  const { socket } = useSocketNotifications();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on(NotificationEvents.productionDataSyncStatus, (status) => {
      console.log('---- ASDASF ----');
      dispatch(
        actions.addProcessStatus({
          message: status.message,
          variant: status.status,
          precessKey: 'productionDataSync',
        }),
      );
    });
  }, [dispatch, socket]);
};
export const useShowNotificationOnGenerateIncentiveReportStatusChangeChange = () => {
  const { socket } = useSocketNotifications();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on(
      NotificationEvents.generateIncentiveReportStatusChange,
      (status) => {
        dispatch(
          actions.addProcessStatus({
            message: status.message,
            variant: status.status,
            precessKey: 'generateIncentiveReportStatusChange',
          }),
        );
      },
    );
  }, [dispatch, socket]);
};
