import { Tooltip } from '@mui/joy';
import { map } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch } from 'react-redux';
import { ComboBoxOption } from '../../components/ComboBox';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { NoIcon, YesIcon } from '../../components/Icons';
import { ModelsToDelete, useHasUserAccessToAction } from '../../config';
import {
  deleteEmployeeRequest,
  exportEmployeeRequest,
  getEmployeeCountRequest,
  getEmployeeListRequest,
} from '../../modules/actions';
import { getDepartmentOptionsByGloballySites } from '../../modules/selectors/department';
import {
  getEmployeeCount,
  getEmployeesTable,
  getIsEmployeesDataLoading,
} from '../../modules/selectors/employee';
import { getPositionsComboboxList } from '../../modules/selectors/position';
import { getShiftOptionsByGloballySites } from '../../modules/selectors/shift';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import { getSupervisorsAndGMsComboboxList } from '../../modules/selectors/user';
import { IdsArray } from '../../modules/types';
import {
  useCreateExportProps,
  useFilter,
  useEmployeesDefaultFilters,
  useBrowserHistoryFunctions,
  useEmployeeComboboxesDataFetchersList,
} from '../../modules/utils/hooks';
import { useDefaultHiddenHeadCells } from '../../modules/utils/hooks/table';
import { useSelector } from 'react-redux';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { CreateEmployee } from './EmployeesForms/CreateEmployee';
import { UpdateEmployees } from './EmployeesForms/UpdateEmployee';
import { EmployeesAddReview } from './EmployeesAddReview';
import {
  ClockPlusSvg,
  GraduationHat01Svg,
  NotificationTextSvg,
  TrendDown01Svg,
} from 'src/components/svgIcons';
import { EmployeesLogTime } from './EmployeesLogTime';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { ICustomAction } from 'src/components/EnhancedTable/EnhancedTableSelectedItemsActions';
import { EmployeesPageSkillFlexUpdate } from './EmployeesPageSkillFlexUpdate';
import { EmployeesPagePointCreate } from './EmployeesPagePointCreate';
import { EmployeesSmsNotification } from './EmployeesSmsNotification';

const HEAD_CELLS_HIDDEN_BY_DEFAULT = [
  'supervisor.firstName',
  'separationdate',
  'defaultShift.name',
  'defaultDepartment.name',
  'phonenumber',
  'wmsLogin',
  'taktId',
  'terminationReason.name',
  'wotc',
  'isWmsLoginPrinted',
  'comments',
];

export const Employees = () => {
  const { t } = useTranslation();
  // create dispatcher
  const dispatcher = useDispatch();

  const { getQueryParamsObject } = useBrowserHistoryFunctions();

  const { review, employeeId } = getQueryParamsObject();

  const defaultFilter = useEmployeesDefaultFilters();

  const { filterCount, filterList } = useFilter(defaultFilter);
  const [
    isCreateEmployeeFormVisible,
    setIsCreateEmployeeFormVisible,
  ] = React.useState(false);
  const [
    isUpdateEmployeeFormVisible,
    setIsUpdateEmployeeFormVisible,
  ] = React.useState(false);
  const [isLogTimeFormVisible, setIsLogTimeFormVisible] = React.useState(false);
  const [isAddReviewFormVisible, setIsAddReviewFormVisible] = React.useState(
    false,
  );
  const [
    isUpdateSkillFlexVisible,
    setIsUpdateSkillFlexVisible,
  ] = React.useState(false);
  const [isUpdatePointsVisible, setIsUpdatePointsVisible] = React.useState(
    false,
  );
  const [
    isSmsNotificationFormVisible,
    setIsSmsNotificationFormVisible,
  ] = React.useState(false);

  const [selectedEmployeesIds, setSelectedEmployeesIds] = React.useState<
    IdsArray
  >([]);

  const [
    isAttachmentsRequestsInProgress,
    setIsAttachmentsRequestsInProgress,
  ] = React.useState(false);

  const SHORT_COMMENT_LENGTH = 50;

  const exportProps = useCreateExportProps(exportEmployeeRequest);

  const { pushSearchObject } = useBrowserHistoryFunctions();

  const comboboxesDataFetchersList = useEmployeeComboboxesDataFetchersList();

  // Make request to fetch shifts, positions and departments whenever defaultFilter is changed
  useEffect(() => {
    comboboxesDataFetchersList.forEach((func) => {
      func();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  useEffect(() => {
    if (review && employeeId) {
      setSelectedEmployeesIds([+employeeId]);
      setIsAddReviewFormVisible(!!review);
    }
  }, [review, employeeId]);

  const list = useSelector(getEmployeesTable);

  const isTableDataLoading = useSelector(getIsEmployeesDataLoading);

  const allowedToCreateEmployee = useHasUserAccessToAction(
    manageEntitiesConfig.employee.create.id,
  );
  const allowedToUpdateEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.employee.update.id,
  );
  const allowedToDeleteEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.employee.delete.id,
  );
  const allowedToLogTimeForEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.logtime.create.id,
  );
  const allowedToMakeReviewForEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.employee_review.create.id,
  );
  const allowedToAddPoints = useHasUserAccessToAction(
    manageEntitiesConfig.point.create.id,
  );
  const allowedToUpdateEmployeeSkill = useHasUserAccessToAction(
    manageEntitiesConfig.employee_skillFlex.update.id,
  );
  const allowedToSendSmsToEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.employee.send_sms_to_employee.id,
  );
  const allowedToExportEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.employee.export.id,
  );

  // fetch employees positions list from store
  const positions = useSelector(getPositionsComboboxList, shallowEqual);
  // fetch staffing providers list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  // fetch supervisors providers list from store
  const supervisors = useSelector(
    getSupervisorsAndGMsComboboxList,
    shallowEqual,
  );
  // fetch employees shifts list from store
  const shiftsByGlobalSites = useSelector(
    getShiftOptionsByGloballySites,
    shallowEqual,
  );
  const shiftsByGlobalSitesWithSiteName = shiftsByGlobalSites.map((shift) => ({
    ...shift,
    name: `${shift.name} (${shift.site?.name})`,
  }));
  // fetch employees departments list from store
  const departmentsByGlobalSites = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          wotc: item.wotc ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          isWmsLoginPrinted: item.isWmsLoginPrinted ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          active: item.active ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          canRehire: item.canRehire ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          isKronosAudited: item.isKronosAudited ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          isYMSAudited: item.isYMSAudited ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          isOtherAudited: item.isOtherAudited ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          comments:
            item.comments && item.comments?.length > SHORT_COMMENT_LENGTH ? (
              <Tooltip title={item.comments}>
                <span>
                  {item.comments.substring(0, SHORT_COMMENT_LENGTH) + '...'}
                </span>
              </Tooltip>
            ) : (
              item.comments
            ),
        };
      }),
    [list],
  );

  const count = useSelector(getEmployeeCount, shallowEqual);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'site.name', disablePadding: false, label: t('employees.site') },
    {
      id: 'firstName',
      disablePadding: false,
      label: t('employees.first_name'),
    },
    { id: 'lastName', disablePadding: false, label: t('employees.last_name') },
    { id: 'employeeId', disablePadding: false, label: t('employees.emp_id') },
    { id: 'badge', disablePadding: false, label: t('employees.badge') },
    {
      id: 'position.name',
      disablePadding: false,
      label: t('employees.position'),
    },
    {
      id: 'staffing.staffingProvider',
      disablePadding: false,
      label: t('employees.staffing_provider'),
    },
    {
      id: 'supervisor.firstName',
      disablePadding: false,
      label: t('employees.supervisor_fname'),
    },
    {
      id: 'supervisor.lastName',
      disablePadding: false,
      label: t('employees.supervisor_lname'),
    },
    { id: 'payRate', disablePadding: false, label: t('employees.pay_rate') },
    {
      id: 'dateHired',
      disablePadding: false,
      label: t('employees.date_hired'),
      noWrapTd: true,
    },
    {
      id: 'separationdate',
      disablePadding: false,
      label: t('employees.separation_date'),
    },
    {
      id: 'defaultShift.name',
      disablePadding: false,
      label: t('employees.default_shift'),
    },
    {
      id: 'defaultDepartment.name',
      disablePadding: false,
      label: t('employees.default_department'),
    },
    {
      id: 'emergencyContact',
      disablePadding: false,
      label: t('employees.emergency_contact'),
    },
    { id: 'active', disablePadding: false, label: t('employees.active') },
    { id: 'phonenumber', disablePadding: false, label: t('employees.phone') },
    { id: 'email', disablePadding: false, label: t('employees.email') },
    { id: 'wmsLogin', disablePadding: false, label: t('employees.wms_login') },
    { id: 'taktId', disablePadding: false, label: t('employees.taktId') },
    {
      id: 'terminationReason.name',
      disablePadding: false,
      label: t('employees.termination_reason'),
    },
    { id: 'wotc', disablePadding: false, label: t('employees.wotc') },
    {
      id: 'canRehire',
      disablePadding: false,
      label: t('employees.can_rehire'),
    },
    {
      id: 'isWmsLoginPrinted',
      disablePadding: false,
      label: t('employees.isWmsLoginPrinted'),
    },
    { id: 'comments', disablePadding: false, label: t('employees.comments') },
    {
      id: 'isKronosAudited',
      disablePadding: false,
      label: t('employees.is_kronos_audited'),
    },
    {
      id: 'isYMSAudited',
      disablePadding: false,
      label: t('employees.is_yms_audited'),
    },
    {
      id: 'isOtherAudited',
      disablePadding: false,
      label: t('employees.is_other_audited'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'site.id',
      label: t('employees.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'firstName',
      label: t('employees.first_name'),
      operator: 'like',
    },
    {
      name: 'lastName',
      label: t('employees.last_name'),
      operator: 'like',
    },
    {
      name: 'employeeId',
      label: t('employees.emp_id'),
      operator: 'like',
    },
    {
      name: 'badge',
      label: t('employees.badge'),
      operator: 'like',
    },
    {
      name: 'position.id',
      label: t('employees.position'),
      operator: 'eq',
      type: 'combobox',
      options: positions as ComboBoxOption[],
    },
    {
      name: 'staffing.id',
      label: t('employees.staffing_provider'),
      operator: 'eq',
      type: 'combobox',
      options: staffingProviders as ComboBoxOption[],
    },
    {
      name: 'supervisor.id',
      label: t('employees.supervisor'),
      operator: 'eq',
      type: 'combobox',
      options: supervisors as ComboBoxOption[],
    },
    {
      name: 'defaultShift.id',
      label: t('employees.default_shift'),
      operator: 'eq',
      type: 'combobox',
      options: shiftsByGlobalSitesWithSiteName,
    },
    {
      name: 'defaultDepartment.id',
      label: t('employees.default_department'),
      operator: 'eq',
      type: 'combobox',
      options: departmentsByGlobalSites,
    },
    {
      name: 'active',
      label: t('employees.active'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
    {
      name: 'isKronosAudited',
      label: t('employees.is_kronos_audited'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
    {
      name: 'isYMSAudited',
      label: t('employees.is_yms_audited'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
    {
      name: 'isOtherAudited',
      label: t('employees.is_other_audited'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
    {
      name: 'isWmsLoginPrinted',
      label: t('employees.isWmsLoginPrinted'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
    {
      name: 'canRehire',
      label: t('employees.can_rehire'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
  ];

  // Hide some columns by default
  useDefaultHiddenHeadCells({
    headCells,
    excludeHeadCellsList: HEAD_CELLS_HIDDEN_BY_DEFAULT,
    tableName: t('employees.table_name'),
  });

  // Make request to fetch employees whenever defaultFilter is changed
  useEffect(() => {
    dispatcher(getEmployeeListRequest(filterList));
    // get total count
    dispatcher(getEmployeeCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    setSelectedEmployeesIds([]);

    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getEmployeeListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getEmployeeCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    pushSearchObject({});

    dispatcher(
      deleteEmployeeRequest({
        data: ids,
        filters: {
          list: filterList.filter ?? {},
          count: filterCount.filter ?? {},
        },
      }),
    );
  };

  const handleUpdating = (ids: IdsArray) => {
    setIsUpdateEmployeeFormVisible(true);
    setSelectedEmployeesIds(ids);
  };
  const handleLogTime = (ids: IdsArray) => {
    setIsLogTimeFormVisible(true);
    setSelectedEmployeesIds(ids);
  };

  const onFillInQuestionnaire = (ids: IdsArray) => {
    setIsAddReviewFormVisible(true);
    setSelectedEmployeesIds(ids);
  };

  const onUpdateSkillFlex = (ids: IdsArray) => {
    setIsUpdateSkillFlexVisible(true);
    setSelectedEmployeesIds(ids);
  };

  const onAddPoints = (ids: IdsArray) => {
    setIsUpdatePointsVisible(true);
    setSelectedEmployeesIds(ids);
  };

  const onCancelShift = (ids: IdsArray) => {
    setIsSmsNotificationFormVisible(true);
    setSelectedEmployeesIds(ids);
  };

  const customActions: Array<ICustomAction> = [];
  if (allowedToSendSmsToEmployees) {
    customActions.push({
      title: t('common.send_message'),
      icon: <NotificationTextSvg />,
      onClick: onCancelShift,
    });
  }
  if (allowedToLogTimeForEmployees) {
    customActions.push({
      title: t('logtimes.log_a_time'),
      icon: <ClockPlusSvg />,
      onClick: handleLogTime,
    });
  }
  if (allowedToUpdateEmployeeSkill) {
    customActions.push({
      title: t('employees.update_skill_flex'),
      icon: <GraduationHat01Svg />,
      onClick: onUpdateSkillFlex,
    });
  }
  if (allowedToAddPoints) {
    customActions.push({
      title: t('employees.add_point'),
      icon: <TrendDown01Svg />,
      onClick: onAddPoints,
    });
  }

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer fullHeight={false}>
          <EnhancedTable
            data={computedList}
            count={count}
            selectIndex="id"
            tableName={t('employees.table_name')}
            headCells={headCells}
            filters={filters}
            onSync={onSync}
            onDelete={allowedToDeleteEmployees ? handleDeletion : undefined}
            onUpdate={allowedToUpdateEmployees ? handleUpdating : undefined}
            onFillInQuestionnaire={
              allowedToMakeReviewForEmployees
                ? onFillInQuestionnaire
                : undefined
            }
            include={defaultFilter.include}
            {...(allowedToExportEmployees ? { exportProps } : {})}
            deleteModelName={ModelsToDelete.Employee}
            isTableDataLoading={
              isTableDataLoading || isAttachmentsRequestsInProgress
            }
            initialFilterData={defaultFilter.where}
            customActions={customActions}
            createEntityBtnProps={
              allowedToCreateEmployee
                ? {
                    title: t('employees.create'),
                    onClick: () =>
                      setIsCreateEmployeeFormVisible((prev) => !prev),
                  }
                : undefined
            }
          />
        </PageContentChildContainer>
      </PageContentWithTopToolbar>

      <>
        <CreateEmployee
          isOpen={isCreateEmployeeFormVisible}
          onClose={() => setIsCreateEmployeeFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />

        <UpdateEmployees
          employeesToUpdateIds={selectedEmployeesIds}
          isOpen={isUpdateEmployeeFormVisible}
          onClose={() => setIsUpdateEmployeeFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />

        <EmployeesLogTime
          employeesToLogTimeIds={selectedEmployeesIds}
          isOpen={isLogTimeFormVisible}
          onClose={() => setIsLogTimeFormVisible(false)}
        />

        <EmployeesAddReview
          employeesToReviewIds={selectedEmployeesIds}
          isOpen={isAddReviewFormVisible}
          onClose={() => setIsAddReviewFormVisible(false)}
        />

        <EmployeesPageSkillFlexUpdate
          employeesIds={selectedEmployeesIds}
          isOpen={isUpdateSkillFlexVisible}
          onClose={() => setIsUpdateSkillFlexVisible(false)}
          onRequestsWithAttachmentsStart={() =>
            setIsAttachmentsRequestsInProgress(true)
          }
          onRequestsWithAttachmentsEnd={() =>
            setIsAttachmentsRequestsInProgress(false)
          }
        />

        <EmployeesPagePointCreate
          employeesIds={selectedEmployeesIds}
          isOpen={isUpdatePointsVisible}
          onClose={() => setIsUpdatePointsVisible(false)}
        />

        <EmployeesSmsNotification
          employeesIds={selectedEmployeesIds}
          isOpen={isSmsNotificationFormVisible}
          onClose={() => setIsSmsNotificationFormVisible(false)}
        />
      </>
    </>
  );
};
